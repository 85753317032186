// Internal useful constants.
const SECOND = 1000;
const MINUTE = 60 * SECOND;
const HOUR = 60 * MINUTE;
const DAY = 24 * HOUR;
const YEAR = 365 * DAY;

// Intervals of time related to Dates in Collection List Filters
export const TIME_INTERVALS_ENUM = {
  days: 'days',
  weeks: 'weeks',
  months: 'months',
  years: 'years',
} as const;

// Tenses of time related to Dates for Collection List Filters
export const FUTURE = 'FUTURE' as const;
export const PAST = 'PAST' as const;

export const TENSES_ENUM = {
  FUTURE,
  PAST,
} as const;

export const TENSES_TO_HUMAN_PHRASES_MAP = {
  FUTURE: 'in the future',
  PAST: 'in the past',
} as const;

export const CLASS_NAME_W_DYN_BIND_EMPTY = 'w-dyn-bind-empty';

export const CONDITION_INVISIBLE_CLASS = 'w-condition-invisible' as const;

export const NON_EXISTING_ITEM_ID = '000000000000000000000000' as const;

export const QUERY_FILTER_FOR_STATES = {
  ALL: 'ALL' as const,
  ANY: 'ANY' as const,
} as const;

// The maximum number of items a set field can contain.
export const SET_FIELD_MAX_ITEMS = 25;

export const COLLECTION_LIST_QUERY_MODES = {
  CURATED: 'curated' as const,
  DYNAMIC: 'dynamic' as const,
} as const;

export type CollectionListQueryMode =
  (typeof COLLECTION_LIST_QUERY_MODES)[keyof typeof COLLECTION_LIST_QUERY_MODES];

/**
 * Granularity in minutes used to schedule publishes. For example: 8:00, 8:05,
 * etc.
 */
export const SCHEDULED_PUBLISH_GRANULARITY_IN_MIN = 5;

/**
 * How far in the future an item can be scheduled for SIP.
 */
export const SCHEDULED_PUBLISH_LIMIT_IN_MS = 5 * YEAR;

/**
 * Grace period to execute a scheduled publish. Let's say something is scheduled
 * to publish at 8:10 and it's 8:20. Is it still ok to publish?
 */
export const SCHEDULED_PUBLISH_GRACE_PERIOD_IN_MS = 30 * MINUTE;

/* An enum of the types of collections in our app. This allows us to handle
 * cases based on collection type by using the function getCollectionType
 */
export const COLLECTION_TYPES = {
  CATEGORIES: 'CATEGORIES' as const,
  CMS_COLLECTIONS: 'CMS_COLLECTIONS' as const,
  PRODUCTS: 'PRODUCTS' as const,
  SKUS: 'SKUS' as const,
} as const;

export const MIN_COLLECTION_LIST_OFFSET = 0;

export const SHARED_ALLOWED_FIELD_TYPES = {
  innerHTML: {
    PlainText: 'innerText',
    HighlightedText: 'innerText',
    RichText: 'innerHTML',
    Number: 'innerText',
    Video: 'innerHTML',
    Option: 'innerText',
    Date: 'innerText',
    Phone: 'innerText',
    Email: 'innerText',
    CommercePrice: 'innerHTML',
    Link: 'innerText',
    ImageRef: false,
    FileRef: false,
    ItemRef: false,
    CommercePropValues: 'innerText',
  },
  'style.color': {
    Color: true,
  },
  'style.background-color': {
    Color: true,
  },
  'style.border-color': {
    Color: true,
  },
  'style.background-image': {
    ImageRef: true,
  },
  src: ['ImageRef'],
  alt: [
    'PlainText',
    'Option',
    'Number',
    'Date',
    'Phone',
    'Email',
    'Video',
    'Link',
  ],
  href: ['Phone', 'Email', 'Video', 'Link', 'FileRef'],
  id: ['PlainText'],
  for: ['PlainText'],
  value: ['Number', 'PlainText'],
  checked: ['Bool'],
  dataWHref: ['PlainText', 'FullSlug'],
};

export const DATETIME_FORMAT_OPTIONS: string[] = [
  'MMMM D, YYYY', // June 29, 2024
  'MMMM D, YYYY h:mm A', // June 29, 2024 12:00 AM
  'MMMM D, YYYY H:mm', // June 29, 2024 00:00
  'MMM D, YYYY', // Jun 29, 2024
  'MMM D, YYYY h:mm A', // Jun 29, 2024 12:00 AM
  'MMM D, YYYY H:mm', // Jun 29, 2024 00:00
  'dddd, MMMM D, YYYY', // Monday, June 29, 2024
  'DD MMMM YYYY', // 06 29 2024
  'DD MMM YYYY', // 06 Jun 2024
  'DD MMM YY', // 06 Jun 24
  'D MMMM YYYY', // 29 June 2024
  'MMM Do, YYYY', // Jun 29th, 2024
  'MMMM Do, YYYY', // June 29th, 2024
  'DD.MM.YYYY', // 06.29.2024
  'M/D/YYYY', // 6/29/2024
  'M.D.YYYY', // 6.29.2024
  'D/M/YYYY', // 29/6/2024
  'D.M.YYYY', // 29.6.2024
  'M/D/YYYY h:mm A', // 6/29/2024 12:00 AM
  'M/D/YYYY H:mm', // 6/29/2024 00:00
  'M.D.YYYY h:mm A', // 6.29.2024 12:00 AM
  'M.D.YYYY H:mm', // 6.29.2024 00:00
  'D/M/YYYY h:mm A', // 29/6/2024 12:00 AM
  'D/M/YYYY H:mm', // 29/6/2024 00:00
  'D.M.YYYY h:mm A', // 29.6.2024 12:00 AM
  'D.M.YYYY H:mm', // 29.6.2024 00:00
  'M/D/YY', // 6/29/24
  'M.D.YY', // 6.29.24
  'D/M/YY', // 29/6/24
  'D.M.YY', // 29.6.24
  'M/D/YY h:mm a', // 6/29/24 12:00 AM
  'M/D/YY H:mm', // 6/29/24 00:00
  'M.D.YY h:mm a', // 6.29.24 12:00 AM
  'M.D.YY H:mm', // 6.29.24 00:00
  'D/M/YY h:mm a', // 29/6/24 12:00 AM
  'D/M/YY H:mm', // 29/6/24 00:00
  'D.M.YY h:mm a', // 29.6.24 12:00 AM
  'D.M.YY H:mm', // 29.6.24 00:00
  'YYYY-MM-DD', // 2024-06-29
  'YYYY-MM-DD h:mm a', // 2024-06-29 12:00 AM
  'YYYY-MM-DD H:mm', // 2024-06-29 00:00
  'MMM D', // Jun 29
  'D MMM', // 29 Jun
  'MMMM YYYY', // June 2024
  'MMM YYYY', // Jun 2024
  'MM/YYYY', // 06/2024
  'h:mm a', // 12:00 AM
  'H:mm', // 00:00
  'D', // 29
  'DD', // 0629
  'ddd', // Mon
  'dddd', // Monday
  'M', // 6
  'MM', // 06
  'MMM', // Jun
  'MMMM', // June
  'YY', // 24
  'YYYY', // 2024
];

export const DATE_FORMAT_OPTIONS: string[] = DATETIME_FORMAT_OPTIONS.filter(
  (format) => !/[hHmaA]/.test(format)
);
